/* Font definitions */
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Dancing+Script&family=Great+Vibes&family=Pinyon+Script&display=swap');

/* Ensure fonts are loaded and available */
.font-dancing-script {
  font-family: 'Dancing Script', cursive !important;
}

.font-great-vibes {
  font-family: 'Great Vibes', cursive !important;
}

.font-pinyon-script {
  font-family: 'Pinyon Script', cursive !important;
}

.font-alex-brush {
  font-family: 'Alex Brush', cursive !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;